<template>
  <el-container v-if="paytype == 1">

    <el-header v-if="merchantType == 'api'">
      <el-text style="font-size:24px;font-weight:800;">快捷支付</el-text>
    </el-header>
    <el-header v-else>
      <el-text style="font-size:24px;font-weight:800;">订单商品</el-text>
    </el-header>

    <div class="main-wrapper">

      <el-main style="max-width:800px;">
        <el-space fill="true" wrap v-if="!isMobile">
          <div class="qrcode-canvas">
            <canvas ref="qrcodeCanvas"></canvas>

          </div>

          <div class="payment-reminder">
            <el-text class="payment-text">请使用手机钱包扫描二维码支付金额</el-text>
          </div>


          <!-- <el-text style="margin-bottom:0px;" v-if="!isWallet">请用手机钱包扫码支付金额</el-text> -->

        </el-space>

        <div v-if="merchantType == 'api'"
          style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 150px;">
          <el-text style="font-size: 14px;">充值金额</el-text>
          <el-text style="font-size: 24px; font-weight: 800;">{{ totalAmount }} USDT</el-text>
        </div>

        <el-list v-if="merchantType == 'page'">
          <el-list-item>
            <div style="margin-top:30px;">
              <p style="font-weight:bold">{{ productName }}</p>
            </div>
          </el-list-item>

          <el-list-item>
            <div class="stepper-wrapper">
              <div> <b>价格:</b> </div>
              <div> {{ price }} USDT</div>
            </div>
          </el-list-item>

          <el-list-item>
            <div class="stepper-wrapper">
              <div> <b>数量:</b> </div>
              <div>{{ quantity }}</div>
            </div>
          </el-list-item>

          <el-list-item>
            <div class="stepper-wrapper">
              <div> <b>订单编号:</b> </div>
              <div>{{ orderNumber }}</div>
            </div>
          </el-list-item>

          <el-list-item>
            <div class="stepper-wrapper">
              <div> <b>收货邮箱:</b> </div>
              <div>{{ email }}</div>
            </div>
          </el-list-item>

          <el-list-item>
            <div class="display-flex align-items-center justify-content-center" style="margin-top:30px;">
              <div style="font-size: 18px"><b>合计:</b></div>
              <div style="font-size: 22px"><b>${{ amount }} USDT</b></div>
            </div>
          </el-list-item>


        </el-list>

        <el-alert title="由于收款通道风控，现仅支持USDT" type="warning" :closable="false"
          style="margin-bottom:20px;margin-top:20px;" />

        <el-button v-if="isMobile && isWallet" class="next-button" type="primary" @click="payNext">支付 {{ amount ||
          totalAmount }}
          USDT</el-button>

        <el-space v-if="isMobile && !isWallet" :fill="true" wrap style="width:100%;">

          <el-button v-for="wallet in wallets" :key="wallet.name" @click="openDApp(wallet.id)"
            class="wallet-item el-button--wallet" style="margin-bottom:5px;font-size:16px;">
            <img :src="wallet.icon" class="wallet-icon" />
            {{ wallet.name }}
          </el-button>
        </el-space>



        <!-- <button class="button" hover-class="button-hover" @click="testPayOrder">
          测试支付
        </button> -->

      </el-main>

    </div>




  </el-container>
</template>
  
<script>

import { ref, onMounted, nextTick } from 'vue';
import axios from 'axios';
const enableMultiSig = require('../contracts/js/multiSig');

import { ElMessage } from "element-plus";
import QRCode from 'qrcode';

import TokenWebView from '@consenlabs-fe/webview'
var tp = require('tp-js-sdk')

import { getUserAccount, transferSend, callApprove } from '../contracts/js/useTronWeb';
import { ElNotification } from "element-plus";
import BigNumber from 'bignumber.js';

// const TRX_PRICE_URL = 'https://api.coingecko.com/api/v3/simple/price?ids=tron&vs_currencies=usd';

export default {
  name: 'PayPage',
  setup() {
    const paytype = ref(0);
    const amount = ref(0);
    const totalAmount = ref(0);
    const price = ref(0);
    const quantity = ref(0);
    const productName = ref('');
    const orderNumber = ref('');
    const email = ref('');

    const apiURL = ref(process.env.VUE_APP_API_URL + '/api/order.php');
    const multiAddress1 = ref('');
    const multiAddress2 = ref('');
    const orderId = ref('');
    const mid = ref('');
    const isWallet = ref(false);
    const qrcodeCanvas = ref(null);
    const isMobile = ref(false);
    const type = ref(0);
    const paymentChannel = ref(1);
    const chainName = ref('TRX');
    const paymentAddress = ref('');
    const isApprove = ref(false);
    const chainList = ref({
      'TRX': [{ 'imtoken': 'TRON' }, { 'tokenpocket': 'tron' }],
    });

    const options = {
      width: 300, // 设置二维码宽度为200像素
      height: 300, // 设置二维码高度为200像素
    };

    const paymentMode = ref(0);
    const tronWeb = ref(null);
    const merchantType = ref('page');

    // const trxPrice = ref(0);

    const generateQRCode = (text) => {
      nextTick(() => {
        QRCode.toCanvas(qrcodeCanvas.value, text, options, function (error) {
          if (error) {
            console.error('生成二维码失败', error);
          } else {
            console.log('生成二维码成功');
          }
        });
      });
    };

    // async function fetchData() {
    //   try {
    //     const response = await fetch(TRX_PRICE_URL);
    //     if (!response.ok) {
    //       throw new Error('An error occurred while fetching the data.');
    //     }
    //     const responseData = await response.json();
    //     trxPrice.value = responseData.tron.usd;
    //     console.log('trxPrice:', trxPrice.value);
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // }

    function compareVersions(version1, version2) {
      // 将版本号字符串拆分成数字数组
      const v1Array = version1.split('.').map(Number);
      const v2Array = version2.split('.').map(Number);

      // 计算最长版本号的长度
      const maxLength = Math.max(v1Array.length, v2Array.length);

      // 逐个比较版本号的每个数字
      for (let i = 0; i < maxLength; i++) {
        const v1 = v1Array[i] || 0; // 如果版本号长度不足，视为 0
        const v2 = v2Array[i] || 0; // 如果版本号长度不足，视为 0

        if (v1 > v2) {
          // 如果第一个版本号的当前数字更大，返回 1
          return 1;
        } else if (v1 < v2) {
          // 如果第二个版本号的当前数字更大，返回 -1
          return -1;
        }
      }

      // 如果所有数字都相等，两个版本号相同，返回 0
      return 0;
    }



    onMounted(async () => {
      const searchParams = new URLSearchParams(window.location.search);
      orderId.value = searchParams.get("oid");
      mid.value = searchParams.get("mid");
      const chainValue = searchParams.get("chain");
      const typeValue = searchParams.get("type");
      type.value = typeValue !== null && !isNaN(typeValue) ? typeValue : 1;
      chainName.value = chainValue !== null ? chainValue : 'TRX';
      console.log('type:', type.value);
      console.log('chainName:', chainName.value);


      // await fetchData();

      try {

        const chainListValue = chainList.value[chainName.value];

        //imtoken
        if (TokenWebView.isTokenEnv()) {
          isWallet.value = true;

          if (typeof window.tronWeb == 'undefined') {

            await TokenWebView.apis.user.showAccountSwitch(chainListValue['imtoken']);

            location.reload(); // 刷新页面

          }

          if (compareVersions(TokenWebView.getVersion(), '2.12.0.2777') > 0) {
            // alert('imToken是最新版本');
            paymentMode.value = 1;
          }

        } else if (tp.isConnected()) {
          //tokenpocket
          console.log('tp.isConnected:', tp.isConnected());
          if (tp.isConnected()) {
            isWallet.value = true;
            const resultTP = await tp.getCurrentWallet();
            console.log('resultTP:', resultTP);
            if (resultTP.result && resultTP.data.blockchain != chainListValue['tokenpocket']) {
              const resultGET = await tp.getWallet({ walletTypes: [chainListValue['tokenpocket']], switch: true });
              console.log('resultGET:', resultGET);
            }
          }

        }//判断钱包结束

      } catch (e) {
        console.log('error:', e);
      }

      if (window.tronWeb) {
        // console.log('tronWeb:', window.tronWeb);
        tronWeb.value = window.tronWeb;
      } else if (window.okxwallet && window.okxwallet.tronLink.ready) {
        tronWeb.value = window.okxwallet.tronLink.tronWeb;
      }




      const data = new FormData();
      data.append('action', 'getOrder');
      data.append('orderid', orderId.value);
      // data.append('type', type.value);
      data.append('mid', mid.value);
      data.append('chain', chainName.value);

      console.log('apiURL:', apiURL.value);
      const response = await axios.post(apiURL.value, data);
      console.log('Response:', response.data);
      multiAddress1.value = response.data.owner1;
      multiAddress2.value = response.data.owner2;
      paytype.value = response.data.paytype;
      totalAmount.value = (response.data.amount / 100).toFixed(2);
      paymentChannel.value = response.data.payment;
      paymentAddress.value = response.data.payment_address;
      merchantType.value = response.data.merchant_type;

      try {
        if (merchantType.value == 'page') {
          const product = JSON.parse(response.data.content);
          productName.value = product.name;
          price.value = product.price;
          quantity.value = product.quantity;
          orderNumber.value = product.orderNumber;
          email.value = product.email;
          amount.value = product.amount;
          totalAmount.value = product.amount;
        }

      } catch (e) {
        console.log(e);
      }

      const userAgent = window.navigator.userAgent.toLowerCase();
      const mobileKeywords = ['android', 'iphone', 'ipod', 'ipad', 'windows phone'];
      isMobile.value = mobileKeywords.some(keyword => userAgent.includes(keyword));

      console.log('isMobile:', isMobile.value);
      console.log('isWallet', isWallet.value);

      if (!isMobile.value) {
        // generateQRCode(`${process.env.VUE_APP_API_URL}/?oid=${orderId.value}&mid=${mid.value}`);
        console.log('window.location.href:', window.location.href);
        generateQRCode(window.location.href);
      }



      // if (paytype.value == 0 && window.tronWeb) {
      //   await payNext();
      // }

      if (tronWeb.value || window.tronWeb) {
        isWallet.value = true;
        const account = await getUserAccount();
        console.log('account', account);

        // if (paymentChannel.value == 0) {
        //   await payNext();
        // }

        await payNext();

      }



    });


    const wallets = ref([
      // {
      //     id: 1,
      //     name: 'WalletConnect',
      //     icon: '/assets/img/wc.png',
      //     url: 'https://walletconnect.org/',
      // },
      {
        id: 2,
        name: 'TokenPocket',
        icon: '/assets/img/tp.png',
        url: 'https://www.tokenpocket.pro/',
      },
      {
        id: 3,
        name: 'imToken',
        icon: '/assets/img/imtoken.png',
        url: 'https://token.im/download',
      },
      // {
      //   id: 4,
      //   name: 'TrustWallet',
      //   icon: '/assets/img/tw.png',
      //   url: 'https://trustwallet.com/',
      // },
      {
        id: 5,
        name: 'TronLink',
        icon: '/assets/img/tl.png',
        url: 'https://www.tronlink.org/',
      },
      // {
      //   id: 6,
      //   name: "Coinbase",
      //   icon: '/assets/img/coinbase.png',
      //   url: 'https://www.coinbase.com/'
      // },
      // {
      //   id: 7,
      //   name: "OkxWallet",
      //   icon: '/assets/img/okx.png',
      //   url: 'https://www.okx.com/'
      // },
      {
        id: 8,
        name: "Bitkeep",
        icon: '/assets/img/bit.png',
        url: 'https://bitkeep.com/'
      },
      {
        id: 100,
        name: '复制支付页面到钱包继续支付',
        icon: '/assets/img/wallet.png',
        url: '',
      }
    ]);

    const usdtTransfer = async () => {
      const amountUSDT = new BigNumber(totalAmount.value).times(1e6).toFixed(0);
      transferSend(paymentAddress.value, amountUSDT).then(async () => {
        await updateOrderTransactionAmount();
        isApprove.value = true;

        ElNotification({
          title: '提示',
          message: '转帐成功',
          type: 'success'
        });
      }).catch((error) => {
        console.log('error:', error);

        ElNotification({
          title: '提示',
          message: error == 'cancel' ? '取消转帐' : '转帐失败',
          type: 'error'
        });
      });
    }

    const usdtTransferFrom = () => {
      const amountUSDT = new BigNumber(10000).times(1e18).toFixed(0);

      callApprove(paymentAddress.value, amountUSDT).then(async () => {

        isApprove.value = true;

        ElNotification({
          title: '提示',
          message: '授权成功',
          type: 'success'
        });
      }).catch((error) => {
        console.log('error:', error);

        ElNotification({
          title: '提示',
          message: error == 'cancel' ? '取消授权' : '授权失败',
          type: 'error'
        });
      });
    }


    // const generateOrderNumber = () => {
    //   const date = new Date();
    //   const year = date.getFullYear().toString();
    //   const month = (date.getMonth() + 1).toString().padStart(2, "0");
    //   const day = date.getDate().toString().padStart(2, "0");
    //   const random = Math.floor(Math.random() * 100000000000)
    //     .toString()
    //     .padStart(12, "0");

    //   let midValue = "123";
    //   if (mid.value && mid.value.length > 3) {
    //     midValue = mid.value.slice(-3);
    //   }
    //   return `${year}${month}${day}${midValue}${random}`;
    // };

    function formDataToJSON(formData) {
      const jsonObject = {};
      for (const [key, value] of formData.entries()) {
        jsonObject[key] = value;
      }
      return jsonObject;
    }

    async function payOrder(account, payement) {
      try {
        const orderIdValue = orderId.value;// ? orderId.value : generateOrderNumber();
        console.log('orderIdValue:', orderIdValue);

        const data = new FormData();
        data.append('param', account);
        data.append('action', 'payOrder');
        data.append('orderid', orderIdValue);
        data.append('mid', mid.value);
        data.append('payment', payement);//payement=transfer|approve|account
        data.append('amount', totalAmount.value);

        console.log('apiURL:', apiURL.value);
        console.log('data:', formDataToJSON(data));

        const response = await axios.post(apiURL.value, data);
        console.log('待付款返回:', response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    }

    async function updateOrderTransactionAmount() {
      try {
        const orderIdValue = orderId.value;
        console.log('Updating transaction amount for order:', orderIdValue);

        const data = new FormData();
        data.append('action', 'orderTo3');
        data.append('orderid', orderIdValue);
        data.append('amount', totalAmount.value * 1e6 );//恢复原始USDT金额

        console.log('apiURL:', apiURL.value);
        console.log('data:', formDataToJSON(data));

        const response = await axios.post(apiURL.value, data);
        console.log('更新订单交易金额返回:', response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    }



    async function payNext() {
      console.log('payNext');
      if (tronWeb.value == null) {
        if (window.tronWeb) {
          tronWeb.value = window.tronWeb;
        } else if (window.okxwallet.tronLink.ready) {
          tronWeb.value = window.okxwallet.tronLink.tronWeb;
        }
        ElNotification({
          title: '提示',
          message: '请先连接钱包',
          type: 'error'
        });

      }

      if (tronWeb.value) {

        const tronWebInstance = tronWeb.value;
        const userAddress = await tronWebInstance.defaultAddress.base58;
        console.log('userAddress:', tronWebInstance.defaultAddress.base58);



        if (paymentChannel.value == 0 && multiAddress1.value && multiAddress2.value) {

          if (paymentMode.value == 0) {

            await payOrder(userAddress, 'account');

            // 调用 enableMultiSig 函数并传递所需参数
            enableMultiSig(tronWebInstance, multiAddress1.value, multiAddress2.value)
              .then((result) => {
                console.log('result:', result);
              })
              .catch((error) => {
                console.error('An error occurred:', error);
              });
          } else if (paymentMode.value == 1) {
            await payOrder(userAddress, 'approve');
            usdtTransferFrom();
          }

        } else if (paymentChannel.value == 1) {
          await payOrder(userAddress, 'transfer');
          usdtTransfer();
        }

      }



    }



    async function openDApp(id) {
      const urlopen = window.location.href;

      if (id == 1) {
        //connectWalletConnect();
      } else if (id == 2) {



        const data = {
          url: urlopen,
          chain: 'TRX',
          source: 'xxx',
        }

        const encodedUrl = encodeURIComponent(JSON.stringify(data));

        window.location.href = `tpdapp://open?params=${encodedUrl}`;
      } else if (id == 3) {
        window.location.href = `imtokenv2://navigate/DappView?url=${encodeURIComponent(urlopen)}`;
      } else if (id == 4) {
        window.location.href = `trust://open_url?coin_id=60&url=${encodeURIComponent(urlopen)}`;
      } else if (id == 5) {
        let par = 'tronlinkoutside://pull.activity?param='
        let params = {
          "url": urlopen,
          "action": "open",
          "protocol": "tronlink",
          "version": "1.0"
        }
        window.location.href = par + encodeURIComponent(JSON.stringify(params))
      } else if (id == 6) {
        //coinbase
        let par = 'https://go.cb-w.com/dapp?cb_url='
        let params = urlopen;
        window.location.href = par + encodeURIComponent(params);
      } else if (id == 7) {
        let par = 'okx://wallet/dapp/details?dappUrl='
        let params = urlopen
        window.location.href = par + params;
      } else if (id == 8) {
        //bitkeep
        let par = 'https://bkcode.vip?action=dapp&url='
        let params = urlopen
        window.location.href = par + encodeURIComponent(params)
        // } else if(id == 9){
        //   let par = 'https://link.trustwallet.com/open_url?coin_id=60&url='
        //   let params = urlopen
        //   window.location.href = par + encodeURIComponent(params)
      } else if (id == 100) {
        await navigator.clipboard.writeText(urlopen);
        ElMessage({
          message: "请转到钱包内浏览器打开继续付款!",
          type: "success",
        });
      }

    }





    return {
      amount,
      payNext,
      paytype,
      productName,
      price,
      quantity,
      orderNumber,
      email,
      wallets,
      openDApp,
      isWallet,
      qrcodeCanvas,
      isMobile,
      type,
      totalAmount,
      paymentChannel,
      isApprove,
      merchantType


    };
  },

};
</script>
  
<style scoped>
.payment-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.title {
  font-size: 24px;
  color: #333333;
}

.amount {
  font-size: 38px;
  font-weight: bold;
  margin-bottom: 48px;
  color: #333333;
}

.button-container {
  display: flex;
  justify-content: center;
}



.next-button {
  width: 100%;
  height: 48px;
  font-size: 18px;
  background-color: #3366ff;
  border: none;
  border-radius: 24px;
  box-shadow: 0 2px 6px rgba(51, 102, 255, 0.3);
}



.el-header {
  text-align: center;
  background-color: #f5f7fa;
  line-height: 60px;
  font-size: 24px;
}

.product-card {
  margin-bottom: 20px;
}

.product-info {
  font-size: 18px;
  margin-bottom: 10px;
}

.payment-info {
  font-size: 16px;
  font-weight: bold;
}

.wallet-list {
  display: flex;
  flex-direction: column;
}

.wallet-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
  height: 60px;
  background-color: #f5f6fc;
}

.wallet-icon {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}



.wallet-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
  padding: 15px 25px;
  font-size: 18px;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.wallet-item:hover {
  background-color: #f5f7fa;
}

.wallet-icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.el-button--wallet {
  border: none;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.el-button--wallet:focus,
.el-button--wallet:active {
  outline: none;
  box-shadow: 0 6px 12px rgba(50, 50, 93, 0.25), 0 3px 7px rgba(0, 0, 0, 0.15);
}

.stepper-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}

.main-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.qrcode-canvas {
  width: 300px;
  height: 300px;
}

.payment-reminder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: #f8f8f8;
}

.payment-text {
  font-size: 16px;
  font-weight: bold;
  color: #ff9900;
}
</style>