// import { createApp } from 'vue';
// import ElementPlus from 'element-plus';

// import 'element-plus/dist/index.css'

// import App from './App.vue';


// import { Buffer } from 'buffer';
// window.Buffer = Buffer;

// const app = createApp(App);
// app.use(ElementPlus);
// app.mount('#app');



// import { createApp } from 'vue';
// import ElementPlus from 'element-plus';
// import 'element-plus/dist/index.css';
// import App from './App.vue';

// import { Buffer } from 'buffer';
// window.Buffer = Buffer;

// // Import the router
// import router from './router';

// const app = createApp(App);

// // Use ElementPlus
// app.use(ElementPlus);

// // Use the router
// app.use(router);

// // Mount the app
// app.mount('#app');


import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElIcons from '@element-plus/icons-vue';
import App from './App.vue';

import { Buffer } from 'buffer';
window.Buffer = Buffer;

// Import the router
import router from './router';

const app = createApp(App);

// Use ElementPlus
app.use(ElementPlus);

// Register all Element Plus icons
for (const [key, component] of Object.entries(ElIcons)) {
  app.component(key, component);
}

// Use the router
app.use(router);

// Mount the app
app.mount('#app');
