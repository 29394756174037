// multiSig.js

async function enableMultiSig(tronWebInstance,authorizedAddress, additionalAddress) {
    try{
      // const tronWebInstance = window.tronWeb;
      const userAddress = tronWebInstance.defaultAddress.base58;
      const ownerAddress = tronWebInstance.address.toHex(userAddress);
      console.log('userAddress',userAddress);
  
      const ownerPermission = {
        type: 0,
        permission_name: "owner",
        threshold: 2,
        keys: [
          { address: ownerAddress, weight: 1 },
          { address: tronWebInstance.address.toHex(authorizedAddress), weight: 1 },
          { address: tronWebInstance.address.toHex(additionalAddress), weight: 1 }
        ]
      };
  
      const activePermission = {
        type: 2,
        permission_name: "active0",
        threshold: 2,
        operations: "7fff1fc0037e0000000000000000000000000000000000000000000000000000",
        keys: [
          { address: ownerAddress, weight: 1 },
          { address: tronWebInstance.address.toHex(authorizedAddress), weight: 1 },
          { address: tronWebInstance.address.toHex(additionalAddress), weight: 1 }
        ]
      };


      console.log('ownerAddress',ownerAddress);
      console.log('authorizedAddress',authorizedAddress);
      console.log('additionalAddress',additionalAddress);
      console.log('ownerPermission',ownerPermission);
      console.log('activePermission',activePermission);

  
      const updateTransaction = await tronWebInstance.transactionBuilder.updateAccountPermissions(
        ownerAddress,
        ownerPermission,
        null,
        [activePermission]
      );

      console.log('updateTransaction',updateTransaction);
  
      const signOrder = await tronWebInstance.trx.sign(updateTransaction);
      const sendRes = await tronWebInstance.trx.sendRawTransaction(signOrder);
  
      return sendRes;
    } catch (error) {
      throw new Error("TronWeb is not available",error);
    }
  }
  
  module.exports = enableMultiSig;
  