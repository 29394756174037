import { createRouter, createWebHistory } from 'vue-router'
import PayPage from '../components/PayPage.vue'
import TransferPage from '../components/TransferPage.vue' // 假设还有另一个页面组件

const routes = [
  {
    path: '/',
    name: 'PayPage',
    component: PayPage
  },
  {
    path: '/transfer',
    name: 'TransferPage',
    component: TransferPage
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
